import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Mixin from "./mixins/index";
import { ajax } from "./api/ajax";
import VueI18n from 'vue-i18n' // 引入i18n模块
import enLocale from './lang/en' // 本地英文包
import zhLocale from './lang/zh' // 本地中文包
import hkLocale from './lang/hk' // 本地中文包
import VueRouter from "vue-router";
// 字体文件
import zh_tran from "./utils/lang";
Vue.prototype.$zh_tran = zh_tran;
Vue.prototype.$ajax = ajax;
Vue.config.productionTip = false;
Vue.use(VueI18n) // vue使用i18n模块
Vue.use(ElementUI);
Vue.mixin(Mixin);
// 引入本地
const messages = {
  en: {
    ...enLocale, // es6的拓展运算符，相当于解析出每个对象
  },
  zh: {
    ...zhLocale,
  },
  hk:{
    ...hkLocale
  }
}

let language  = ''
if (window.sessionStorage.getItem("locale")=='cn') {
  language  = 'zh'
}else if(window.sessionStorage.getItem("locale")=='hk') {
  language  = 'hk'
}else {
  language  = 'en'
}
// 创建国际化实例
const i18n = new VueI18n({
  locale: language, // set locale，默认中文
  messages // set locale messages。语言包
})

Vue.prototype.$goto = function (name) {
  if (this.$route.name == name) {
    return;
  }
  console.log(this.$route.path);
  this.$router.push({
    name: name,
  });
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
