export default {
    home:{
        1:'Integrity-Transparency-Security-Quality',
        2:'Start trading on',
        3:"forex",
        4:'indices, commodities',
        5:"shares and more.",
        6:'Gear up for high performance with technology',
        7:"Start Now",
        8:"PRODUCTS",
        9:"CLASSES",
        10:"FEES",
        11:"SUPPORT",
        12:"LEADING BROKER",
        13:"We’re dedicated to establishing a long-term relationship with you to help you trade successfully",
        14:"Deep Liquidity",
        15:"Technology Driven",
        16:"Funds Security",
        17:"Balance Protection",
        18:"TRADING PLATFORM",
        19:"Tight Spreads And Ultra-Fast Execution",
        20:"Best market prices available so you can receive excellent conditions.",
        21:"Negative balance protection",
        22:"Segregated and supervised client funds",
        23:"Instant deposit & fast withdrawal",
        24:"Trade forex markets wherever you are, whenever the market is open.",
        25:"Best market prices available so you can receive excellent conditions.",
        26:"Negative balance protection",
        27:"Segregated and supervised client funds",
        28:'Instant deposit & fast withdrawal',
        29:"Download for",
        30:"Microsoft Store",
        31:"App Store",
        32:"Android apk",
        33:"Weekly market update",
        34:"view more",
        35:"cooperative partner",
        36:"Liquidity Providers",
        37:"cooperative partner",
        38:"Send us a message",
        39:"SEND MESSAGE",
        40:"Ready to get started?",
        41:"OPEN YOUR ACCOUNT",
        42:"The name cannot be blank",
        43:"The mailbox cannot be empty",
        44:"Please fill in the message information",
        45:"Submitted successfully",
        46:"Submission failed",
        47:"Name",
        48:"E-mail*",
        49:"Phone",
        50:"Your Message*",
        51:"PORTAL"
    },
    about:{
        1:'AURORA EXPLORE PTY LTD(AEFOREX)',
        2:"AEFOREX is an international top foreign exchange broker, full name AURORA EXPLORE PTY LTD. AEFOREX relies on the METAQUOTES trading platform and the world's top trading team to provide a full range of authoritative information providers for foreign exchange, precious metals, CFDs, global index and business transaction services, accounts and fund management. We are committed to providing our customers with the lowest transaction costs and the most perfect trading experience in the industry, providing the world's investors with cutting-edge, best investment opportunities.",
        3:'Group goals',
        4:"Financial information output partners",
        5:'STP/ECN Bridge Provider',
        6:'CREATE ACCOUNT',
        7:'A premium online Forex and CFD broker',
        8:"Why choose to trade with us",
        9:"AEFOREX is an international top foreign exchange broker, full name AURORA EXPLORE PTY LTD. AEFOREX relies on the METAQUOTES trading platform and the world's top trading team to provide a full range of authoritative information providers for foreign exchange, precious metals, CFDs, global index and business transaction services, accounts and fund management. We are committed to providing our customers with the lowest transaction costs and the most perfect trading experience in the industry, providing the world's investors with cutting-edge, best investment opportunities.",
        10:"Discover Transparent Forex Trading",
        11:'Attractive Spreads',
        12:'Get more for your money and trade freely with us. Enjoy no fees or commission on all your trades.',
        13:'No Requotes',
        14:'Because of our ultra-fast execution and price feeds, there are no requotes when trading with us whatsoever.',
        15:'Leveraged Trading',
        16:'Potentially benefit from the ability to control larger positions with small funds during every trade.',
        17:'Alternative Investments',
        18:'Gain exposure to a completely new range of instruments and diversify their overall portfolio risk.',
    },
    commodity:{
        1:'Why trade Commodity with FX',
        2:'Enjoy wide range of offers from the biggest commodities exchanges from all over the world',
        3:'Commodity  Contract Specifications',
        4:'Product',
        5:'Description',
        6:'Swap Long',
        7:'Swap Short',
        8:'Minimum Size',
        9:'Contract Value',
        10:'Fluctuation',
        11:"Commodity Trading Strategies",
        12:'Attractive Spreads',
        13:'Get more for your money and trade freely with us. Enjoy no fees or commission on all your trades.',
        14:'No Requotes',
        15:'Because of our ultra-fast execution and price feeds, there are no requotes when trading with us whatsoever.',
        16:'Leveraged Trading',
        17:'Potentially benefit from the ability to control larger positions with small funds during every trade.',
        18:"Alternative Investments",
        19:'Gain exposure to a completely new range of instruments and diversify their overall portfolio risk.'
    },
    download:{
        1:'Universal Platform',
        2:'MT4 is one of the world’s most popular trading platforms, featuring automatic monitoring, advanced charts and live analysis.',
        3:'Technical Indicators',
        4:'Over 50 built-in technical indicators to help you follow the market and discover trading patterns and signals for entry and exit points.',
        5:'Easy to use',
        6:"MT4’s strength is in its simplicity. Its classic design and powerful analytical tools make it a mainstay within the trading industry.",
        7:'Expert Advisors',
        8:"Utilize the MT4's unique feature of EA's by using the many available solutions for automated trading from the MQL4 community.",
        9:'One–click Trading',
        10:'Delivers fast and easy trade entry and exits. Advanced order tickets let you control risks and capture your rewards on every trade',
        11:"Strategy Tester",
        12:'Test and optimize your EA with MT4 strategy tester. Back test your strategies on all market assets and find the best solution.',
        13:'DOWNLOAD METATRADER 4 AND OPEN A DEMO ACCOUNT TO RECEIVE  ALL THIS FOR FREE!',
    },
    faq:{
        1:'Account',
        2:"Fund",
        3:'Platform',
        4:'Transaction'
    },
    forex:{
        0:'Why trade Forex with AEFOREX',
        1:"We strive to make forex trading accessible to anyone.",
        2:"Forex Contract Specifications",
        3:"Product",
        4:"Description",
        5:"Swap Long",
        6:"Swap Short",
        7:"Minimum Lots",
        8:'Contract Value',
        9:"Fluctuation",
        10:'Deep Liquidity',
        11:'We strive to make forex trading accessible to anyone.',
        12:'Trading Currencies',
        13:'We offers trading access to major, minor, and emerging pairs 24 hours a day.',
        14:'Order Execution',
        15:'Trades are routed directly to our servers, with no dealer intervention.',
        16:'Tight Spreads',
        17:'Instruments are quoted at the best possible prices from our liquidity providers.',
        18:'No Requotes',
        19:'Your order will always be executed at the requested price, every time.',
        20:'Flexible Leverage',
        21:'Gain a much larger market exposure with a relatively smaller initial deposit.',
        22:"Forex Trading Strate"
    },
    fund:{
        1:"Instant Deposits.Fast Withdrawals.No Fees.",
        2:"Fund your account easily and securely through our wide range of payment options. Your funds are held securely in a Tier 1 Australian Bank.",
        3:"Deposits",
        4:"Withdrawals",
        5:"Method",
        6:"Processing Time",
        7:"Minimum",
        8:"Fees",
        9:"Important Notes"
    },
    glossary:{
        1:"Simple definitions to international markets trading terminology.",
        2:"Name",
        3:"Description"
    },
    indicators:{
        1:"Understanding the importance of each indicator means you are better equipped to trade smart",
        2:"INDICATOR",
        3:"COUNTRY",
        4:"RELEASE DATE",
        5:"GENERAL IMPACT"
    },
    policy:{
        1:"Market Orders",
        2:"Limit Orders",
        3:"Stop Orders",
        4:"Execution Policy"
    },
    program:{
        1:"Why Partner With Us",
        2:"Introducing Broker",
        3:"Allows organizations and individuals to be remunerated for introducing new clients to us. We offer a total solution, from provision of the trading platforms to execution and settlement of transactions.",
        4:"A competitive IB commission structure",
        5:"Commissions paid into a MT4 Trading Account each month",
        6:"Positive Client Experience",
        7:"Promote us and increase your profit potential by referring new partners",
        8:"White Label",
        9:"We offers white label solutions to brokerage firms and banks, enabling them to repackage their liquidity, prices, products and services under their own brand and identity.",
        10:"A competitive IB commission structure",
        11:"Commissions paid into a MT4 Trading Account each month",
        12:"Positive Client Experience",
        13:"Promote us and increase your profit potential by referring new partners",
        14:"Institutional",
        15:"We offers institutional liquidity solutions for financial brokers, banks, hedge funds and asset management companies regardless of account size and volume traded.",
        16:"Simple and straight forward integration with MT4",
        17:"Free consultancy and help with integration and set-up",
        18:"Low cost liquidity solution-commission per volume traded",
        19:"Access to our liquidity but also to our prime broker’s liquidity feed as well",
        20:'Manage your account with us, analyse and track your success with online system.',
        21:'Extensive Reporting',
        22:'For as long as your clients continue to trade, they will generate commissions for you.',
        23:'No Limit Commissions',
        24:'Get exceptional support from experienced Forex affiliate account managers.',
        25:'Sales Support',
        26:'Great commissions with swift payment and flexible solutions to suit your business model',
        27:'Prompt Payment',
        28:'Regular incentives we offer to both our referring partners and potential new clients',
        29:'Regular Incentives',
        30:'Pay your clients an auto-rebate directly into their trading accounts',
        31:'Auto Rebate System',
        32:"Terms & Conditions"
    },
    header:{
        1:"",
        2:"Meta Trader 4",
        3:"The most popular multi-asset class trading platform in the world",
        4:"Home",
        5:"HOME",
        6:"PRODUCT",
        7:"PLATFORM",
        8:"",
        9:"ACCOUNT",
        10:"CONDITION",
        11:"",
        12:"Economic Indicators",
        13:"",
        14:"",

    },
    footer:{
        1:"THE AWARD WINNING BR",
        2:"PROFIT LOSS",
        3:"WORLD FINANCE",
        4:"FX WEEK",
        5:"EUROMONEY",
        6:"Registration Address：Level 7, 333-339 George Street, SYDNEY NSW 2000 Australian",
        7:"Office address",
        8:"RISK WARNING",
        9:"Trading Forex and CFDs on margin carries a high level of risk and may not be suitable for all investors. Leverage can work both to your advantage and disadvantage. There is a possibility that you may lose all of your initial investments, so you should not risk more than you are prepared to lose. Prior to trading you should make sure you fully understand all the risks involved and take into consideration your level of experience and financial situation.",
        10:"DISCLAIMER",
        11:"All information on this website does not constitute a direct investment invitation or suggestion for any financial products of AURORA EXPLORE PTY LTD. The website content is for reference only. Website visitors should not rely on the information on this website and consider it as a basis for action or inaction. We shall not be liable for any actions or omissions taken by any individual based on this website or its information, resulting in any consequences. Any products and services provided on this website are not applicable to residents of specific countries such as the United States, Canada, Israel, and the Islamic Republic of Iran.",
        12:"AURORA EXPLORE PTY LTD",
        13:"Email",
        14:'Phone',
        15:'Registration No'
    },
    hores:{
        1:"Markets Trading Hours",
        2:"One of the advantages of forex trading is the ability to trade 24 hours a day, five days a week. That’s because the markets open on Monday Sydney time and close on Friday New York time.",
        3:"Daylight Savings Time (DST) is generally applicable in autumn and spring, however it is not equally applicable to all instruments. There will be instruments that apply DST to USA times, with the EU or APAC times, while others may not apply DST at all.",
        4:"Forex",
        5:"Commodity",
        6:"Indices",
        7:"Shares",
        8:"Product",
        9:"Start Trading",
        10:"Close Trading",
        11:"Trading Break",
        
    },
    indices:{
        1:"Why trade Indices with FX",
        2:"Offers many commonly-used market index products as well as exchange-traded index futures",
        3:"Indices  Contract Specifications",
        4:"Product",
        5:"Description",
        6:"Swap Long",
        7:"Swap Short",
        8:"Minimum Size",
        9:"Minimum Step",
        10:"Contract Value",
        11:"Some of the things you may want to know",
        12:" We answered questions so you don’t have to ask.",
        
      
        13: 'Instant participation in the global economy',

        14: "Providing equal opportunities for every trader to enter these markets, you can participate in world-renowned financial activities by trading index price difference contracts",

        15: 'High leverage and low cost',

        16: "Provide better leverage for index futures contracts, gain greater market exposure with smaller initial margin, and release more funds to trade new orders",

        17: "Trading the most popular CFDs in various regions",

        18: "Covering the most popular indices from around the world, it serves as an attractive alternative to diversified investment portfolios",

        19: "Short selling and long selling are profitable",

        20: "You can easily participate in index trading by buying or selling, with the same margin requirements for short and long positions, and no additional selling rules",
    },
    router:{
        1:"home",
        2:"Forex",
        3:"Commodity",
        4:"details",
        5:"Indices",
        6:"Download",
        7:"Funding",
        8:"Economic Indicators",
        9:"FAQ",
        10:"Market Analysis",
        11:"Trading Hours",
        12:"Order Execution Policy",
        13:"Terms Glossary",
        14:"Introduce",
        15:"Terms of Use",
        16:"Liquidity Program",
         17:"",
         18:"Regulatory security",
    },
    analysis:{
        1:"Market Analysis",
        2:"Product dynamics",
        3:"Company Announcement"
    }
}
