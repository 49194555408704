export default  {
    home:{

        1:"诚信-透明-安全-品质",
        
        2:"投资全球金融市场,",
        
        3:"外汇",
        
        4:'商品股指',
        
        5:"以及差价合约",
        
        6:"优质报价开启非凡交易之旅",
        
        7:"立即开始",
        
        8:"产品",
        
        9:"类",
        
        10:"费用",
        
        11:"支持",
        
        12:"领先的经纪人",
        
        13:"我们致力于与您建立长期关系,帮助您成功交易",
        
        14:"信任和透明",
        
        15:"尖端的技术",
        
        16:"灵活交易",
        
        17:"负余额保护",
        
        18:"交易平台",
        
        19:"紧密价差和超快速执行",
        
        20:"最优惠的市场价格,所以你可以得到极好的条件。",
        
        21:"负平衡保护",
        
        22:"独立和受监管的客户基金",
        
        23:"即存即取",
        
        24:"无论你身在何处,只要市场开放,就可以交易外汇市场。",
        
        25:"最优惠的市场价格,让您可以获得良好的条件。",
        
        26:"负平衡保护",
        
        27:"独立和受监督的客户基金",
        
        28:"即时存款和快速提款",
        
        29:"下载",
        
        30:"Microsoft商店",
        
        31:"应用商店",
        
        32:"安卓apk",
        
        33:"每周市场更新",
        
        34:"查看更多",
        
        35:"合作伙伴",
        
        36:"流动性提供者",
        
        37:"合作伙伴",
        
        38:"给我们发个消息",
        
        39:"发送消息",
        
        40:"准备好开始了吗？",
        
        41:"开户",
        
        42:"名称不能为空",
        
        43:"邮箱不能为空",
        
        44:"请填写留言信息",
        
        45:"提交成功",
        
        46:"提交失败",
        47:"名称",
        48:"电子邮件*",
        49:"电话",
        50:"您的信息*",
        51:"平台"
        },
    about:{
        1:'AURORA EXPLORE PTY LTD(AEFOREX)极星金融',
        2:"AEFOREX是一家国际顶级外汇经纪商,全名AURORA EXPLORE PTY LTD。AEFOREX依托METACQUOTES交易平台和全球顶级的交易团队,为外汇、贵金属、差价合约、全球指数和商业交易服务、账户和基金管理提供全方位的权威信息提供商。我们致力于为客户提供行业内最低的交易成本和最完美的交易体验,为世界投资者提供前沿、最佳的投资机会。",
        3:"团队目标",
        4:"金融信息输出合作伙伴",
        5:"TP/ECN桥接器提供商",
        6:'创建帐户',
        7:"高级在线外汇和差价合约经纪人",
        8:"为什么选择与我们交易",
        9:"AEFOREX是一家国际顶级外汇经纪商,全名AURORA EXPLORE PTY LTD。AEFOREX依托METACQUOTES交易平台和全球顶级的交易团队,为外汇、贵金属、差价合约、全球指数和商业交易服务、账户和基金管理提供全方位的权威信息提供商。我们致力于为客户提供行业内最低的交易成本和最完美的交易体验,为世界投资者提供前沿、最佳的投资机会。",
        10:"立即体验交易",
        11:"尖端技术优势",

        12:"访问您最喜爱的投资组合从未如此简单，专为寻找交易优势的投资者设计，帮助提高您的交易业绩",

        13:"资金独立",

        14:"所有客户的资金存放于信托账户中，与自有运营资金分离",

        15:"订单快速执行",

        16:"交易即时执行，确保您每次交易都获得最好的价格",

        17:"交易和技术支持",

        18:"我们帮助客户了解全球金融市场并成长为成熟的交易者",
    },
    commodity:{
        1:"为何在AEFOREX交易商品和贵金属",
        2:"享受来自全球市场的商品和贵金属交易所提供的报价",
        3:"商品合约规格",
        4:"产品",
        5:"说明",
        6:"多仓隔夜利息",
        7:"空仓隔夜利息",
        8:"最小规模",
        9:"合同价值",
        10:"波动幅度",
        11:"商品交易策略",
        12:"极具竞争力的点差",
        13:"为客户提供极富吸引力点差、较低的交易门槛以及保证金要求，确保您的每一笔交易都享有最优的利益。",
        14:"无须重新报价",
        15:"我们超快速的执行与报价支持您在任何时段的交易中都无须重新报价，您的订单将始终在您指定的价位即时执行。",
        16:"可作杠杆交易",
        17:"灵活的的杠杆将有效规避交易所较高保证金要求的问题，您将以更小额的资金操控多种商品市场。",
        18:"深度的流动性",
        19:"受益于我们先进的市场解决方案，可以为您提供最好执行和市场深度，即使是大批量的交易都可以用尽可能低的滑点执行。"
    },
    download:{
        1:"实时的交易品种报价",
2:"MT4是世界上最流行的交易平台之一，具有自动监测，先进的图表和实时分析的功能。",
3:"指标技术分析工具",
4:"超过50个内置技术指示器来帮助你遵循市场和发现交易模式以及进场点和出场点信号。",
5:"功能全面的交易系统",
6:"作为最流行的普遍交易平台之一，MT4的优势在于它的经典设计和简单实用",
7:"智能交易系统",
8:"利用从MQL4社群里的各种各样的EA方案，最大化地使用MT4平台的功能。",
9:'高效交易下单',
10:'支持一击成交、移动止损及部分平仓功能，可在开仓前预先设定止盈/止损。',
11:"功能全面的交易系统",
12:"作为最流行的普遍交易平台之一，MT4的优势在于它的经典设计和简单实用。",
13:'下载METATRADER 4并开通一个模拟账户,免费接收所有这些！',
    },
    faq:{
        1:"帐户",

        2:"基金",

        3:"平台",

        4:"交易"
    },
    forex:{
        0:'为何在AEFOREX交易外汇',
        1:"我们致力于让每个人都有机会参与外汇交易",
        2:"外汇合约规格",
        3:"产品",
        4:"说明",
        5:"多仓隔夜利息",
        6:"空仓隔夜利息",
        7:"最小规模",
        8:"合同价值",
        9:"波动幅度",
        10:"银行间市场流动性",
        11:"使用知名的流动性供应商，可以为您提供最好的价格、执行和市场深度。",
        12:"一个账户交易全球",
        13:"使用知名的流动性供应商，可以为您提供最好的价格、执行和市场深度。",
        14:"毫秒级别的交易体验",
        15:"使用知名的流动性供应商，可以为您提供最好的价格、执行和市场深度。",
        16:"享受极低的交易成本",
        17:"使用知名的流动性供应商，可以为您提供最好的价格、执行和市场深度。",
        18:"无重新报价",
        19:'使用知名的流动性供应商，可以为您提供最好的价格、执行和市场深度。',
        20:"灵活杠杆",
        21:"使用知名的流动性供应商，可以为您提供最好的价格、执行和市场深度。",
        22:"外汇基本知识"
    },
    fund:{
        1:"即时存款。快速提款。无需任何费用。",

2:"通过我们广泛的支付选项,轻松安全地为您的账户提供资金。您的资金安全地存放在一级澳大利亚银行。",

3:"存款",

4:"提款",

5:"方法",

6:"处理时间",

7:"最小值",

8:"费用",

9:"重要注意事项"
    },
    indicators:{
        1:"了解每个指标的重要性意味着你更有能力进行明智的交易",

        2:"指示器",
        
        3:"国家",
        
        4:"发布日期",
        
        5:"一般影响"
    },
    policy:{
        1:"市场订单",

        2:"限价订单",

        3:"停止订单",
        4:"执行政策"
    },
    program:{
        1:"为什么选择与我们建立伙伴关系",
        2:"介绍经纪人",
        3:"我们的介绍经纪人(IB)计划允许全世界的机构和个人通过向我们介绍新客户而获得报酬。从交易平台到执行，再到交易结算，我们提供全面的解决方案。我们帮助他们开发业务，确保我们提供最出色的服务。",
        4:"极具竞争力的合作计划与佣金回报",
        5:"详细的推荐客户跟进功能",
        6:"随时提现佣金，没有隐藏费用",
        7:"立即、自动计算佣金，查看佣金报表的全部内容",
        8:"白标合作",
        9:"我们的高级白标签计划提供完善的工具和资源，并且提供问题的最终解决方案，帮助你起步和运作。无论您是哪个阶段的资金管理人与介绍经纪人，我们都能协助您成功搭建属于自己的品牌",
        10:"低启动成本",
        11:"完整的交易管理功能",
        12:"原始/核心点差与高佣金回报计划",
        13:"定制化的公司交易条件",
        14:"机构计划",
        15:"利用与主要流动性提供商的长期合作关系，让机构投资者在全球资本市场获得竞争优势。我们提供先进的解决方案，将最优流动性与多种执行策略结合。它灵活的集成选项专为当今复杂的交易环境设计。",
        16:"全面的机构流动性解决方案",
        17:"根据自身需要进行个性化设置",
        18:"无最低交易量要求",
        19:"多银行报价，零成本桥接安装",
        20:"与我们一起管理您的账户,通过在线系统分析和跟踪您的成功。",
        21:"全面的CRM管理系统",
        22:"通过CRM管理系统推荐及管理您的用户",
        23:"佣金无限制",
        24:"您的收入潜力没有限制，您赚取的佣金完全取决于您所有客户的交易量",
        25:"经纪支持",
        26:"专业团队将协助您推荐的客户开设账户并提供持续的管理和支持。",
        27:"即期付款",
        28:"高额佣金以及快捷付款和灵活解决方案，适合您的商业模式",
        29:"定期激励措施",
        30:"我们会向推荐合作伙伴和新的潜在客户提供定期激励措施增加业绩",
        31:"自动返佣系统",
        32:"条款和条件"
    },
    header:{
        1:"",
        2:"Meta Trader 4",
        3:"全球最受欢迎的多资产类别交易平台",
        4:"首页",
        5:"首页",
        6:"交易产品",
        7:"交易平台",
        8:"",
        9:"交易账户",
        10:"交易条件",
        11:"",
        12:"交易工具",
        13:"",
        14:"",
    },
    footer:{
        1:"获奖BR",

        2:"利润损失",

        3:"世界金融",

        4:"外汇周",

        5:"欧洲货币",

        6:"注册地址:Level 7, 333-339 George Street, SYDNEY NSW 2000 Australian",

        7:"办公地址",

        8:"风险警示",

        9:"本网站仅提供的只是一般信息，并未考虑到您的个人目标、财务状况及需求。因此在决定获得或继续持有任何本网站列出的金融产品之前，您应当仔细考虑您的投资目标、经验多寡和风险承受能力。您有可能损失部分或全部投资，如果无法承担损失，请谨慎投资。您应当认识到与外汇交易有关的一切风险，如果有疑问，请向独立的金融顾问询问意见",

        10:"免责声明",

        11:"本网站上的所有信息不构成对AURORA EXPLORE PTY LTD.任何金融产品的直接投资邀请或建议。网站内容仅供参考。网站访问者不应依赖本网站上的信息,并将其视为采取行动或不采取行动的依据。我们不对任何个人基于本网站或其信息所采取的任何作为或不作为承担任何责任,从而导致任何后果。本网站上提供的任何产品和服务不适用于美国、加拿大、以色列和伊朗伊斯兰共和国等特定国家的居民。",

        12:"AURORA EXPLORE PTY LTD",

        13:"电子邮件",
        14:'电话',
        15:'注册号'
    },
    hores:{
        1:"金融产品常规交易时间",
        2:"外汇交易的一大优点就是能够每周5天，每天24小时进行。这是因为外汇市场在格林威治标准时间周日于悉尼开盘，于格林威治标准时间周五（夏令时）在纽约收盘。",
        3:"夏令时(DST)主要适用于春秋两季，但并不适用于所有金融产品。有些金融产品使用美国夏令时、欧洲夏令时或者亚太地区夏令时，有些则完全不用夏令时。交易时间使用的格林威治标准时间。",
        4:"外汇",
        5:"商品及贵金属",
        6:"CFDs",
        7:"Shares",
        8:"交易产品",
        9:"开市时间",
        10:"休市时间",
        11:"交易间隔",
    },
    glossary:{
        1:"外汇词汇表阐明外汇市场上应用的术语和概念",
        2:"名称",
        3:"描述"
    },
    indices:{
        1:"为何在AEFOREX交易CFDs",
        2:"提供多种常用的全球市场CFDs以及交易所交易指数",
        3:"CFDs合约规格",
        4:"产品符号",
        5:"产品名称",
        6:"多仓隔夜利息",
        7:"空仓隔夜利息",
        8:"最小规模",
        9:"增加规模",
        10:"合约价值",
        11:"外汇基本知识",
        12:" 你可能想知道的一些事情",

        13:'即时参与全球经济',
        14:'为每一位交易者提供进入这些市场的平等机会，您可以通过交易指数差价合约，参与到举世瞩目的金融活动。',
        15:'高杠杆低成本',
        16:'提供更好的指数期货合约杠杆，以较小的初始保证金获得更大的市场曝光，释放更多的资金交易新的订单。',
        17:'交易各地最流行的CFDs',
        18:'覆盖了来自世界各地最流行的指数，作为极有吸引力的多样化投资组合的替代商品。',
        19:'做空做多均能盈利',
        20:'您可以通过买入或卖出，轻松地参与指数交易，做空和做多保证金要求相同，没有额外的卖出规则。',
    },
    router:{
        1:"首页",
        2:"外汇",
        3:"商品和贵金属",
        4:"详情",
        5:"CFDs",
        6:"MetaTrader 4",
        7:"存款和取款",
        8:"经济指标",
        9:"常见问题",
        10:"市场分析",
        11:"市场时间",
        12:"执行政策",
        13:"概念术语",
        14:"公司简介",
        15:"使用条款",
        16:"合作伙伴",
        18:"监管安全",
        17:"财经日历",
    },
    analysis:{
        1:"市场分析",
        2:"AE投研",
        3:"品牌动态"
    }
}