export default  {
    home:{
        1:"誠信-透明-安全-品質",
        2:"投資全球金融市場,",
        3:"外匯",
        4:'商品股指',
        5:"以及差價合約",
        6:"優質報價開啟非凡交易之旅",
        7:"立即開始",
        8:"產品",
        9:"類",
        10:"費用",
        11:"支持",
        12:"領先的經紀人",
        13:"我們致力於與您建立長期關係,幫助您成功交易",
        14:"信任和透明",
        15:"尖端的科技",
        16:"靈活交易",
        17:"負餘額保護",
        18:"交易平臺",
        19:"緊密價差和超快速執行",
        20:"最優惠的市場價格,所以你可以得到極好的條件。",
        21:"負平衡保護",
        22:"獨立和受監管的客戶基金",
        23:"即存即取",
        24:"無論你身在何處,只要市場開放,就可以交易外匯市場。",
        25:"最優惠的市場價格,讓您可以獲得良好的條件。",
        26:"負平衡保護",
        27:"獨立和受監督的客戶基金",
        28:"即時存款和快速提款",
        29:"下載",
        30:"Microsoft商店",
        31:"應用商店",
        32:"安卓apk",
        33:"每週市場更新",
        34:"查看更多",
        35:"合作夥伴",
        36:"流動性提供者",
        37:"合作夥伴",
        38:"給我們發個消息",
        39:"發送消息",
        40:"準備好開始了嗎？",
        41:"開戶",
        42:"名稱不能為空",
        43:"郵箱不能為空",
        44:"請填寫留言資訊",
        45:"提交成功",
        46:"提交失敗",
        47:"名稱",
        48:"電子郵件*",
        49:"電話",
        50:"您的資訊*",
        51:"平臺"
        },
        about:{
        1:'AURORA EXPLORE PTY LTD（AEFOREX）極星金融',
        2: "AEFOREX是一家國際頂級外匯經紀商,全名AURORA EXPLORE PTY LTD。AEFOREX依託METACQUOTES交易平臺和全球頂級的交易團隊,為外匯、貴金屬、差價合約、全球指數和商業交易服務、帳戶和基金管理提供全方位的權威資訊提供商。我們致力於為客戶提供行業內最低的交易成本和最完美的交易體驗,為世界投資者提供前沿 、最佳的投資機會。 ",
        3:"團隊目標",
        4:"金融資訊輸出合作夥伴",
        5:"TP/ECN橋接器提供商",
        6:'創建帳戶',
        7:"高級線上外匯和差價合約經紀人",
        8:"為什麼選擇與我們交易",
        9: "AEFOREX是一家國際頂級外匯經紀商,全名AURORA EXPLORE PTY LTD。AEFOREX依託METACQUOTES交易平臺和全球頂級的交易團隊,為外匯、貴金屬、差價合約、全球指數和商業交易服務、帳戶和基金管理提供全方位的權威資訊提供商。我們致力於為客戶提供行業內最低的交易成本和最完美的交易體驗,為世界投資者提供前沿 、最佳的投資機會。 ",
        10:"立即體驗交易",
        11:"尖端科技優勢",
        12:"訪問您最喜愛的投資組合從未如此簡單,專為尋找交易優勢的投資者設計,幫助提高您的交易業績",
        13:"資金獨立",
        14:"所有客戶的資金存放於信託帳戶中,與自有運營資金分離",
        15:"訂單快速執行",
        16:"交易即時執行,確保您每次交易都獲得最好的價格",
        17:"交易和技術支援",
        18:"我們幫助客戶瞭解全球金融市場並成長為成熟的交易者",
        },
        commodity:{
            1:"為何在AEFOREX交易商品和貴金屬",
            2:"享受來自全球市場的商品和貴金屬交易所提供的報價",
            3:"商品合約規格",
            4:"產品",
            5:"說明",
            6:"多倉隔夜利息",
            7:"空倉隔夜利息",
            8:"最小規模",
            9:"契约價值",
            10:"波動幅度",
            11:"商品交易策略",
            12:"極具競爭力的點差",
            13:"為客戶提供極富吸引力點差、較低的交易門檻以及保證金要求,確保您的每一筆交易都享有最優的利益。",
            14:"無須重新報價",
            15:"我們超快速的執行與報價支持您在任何時段的交易中都無須重新報價,您的訂單將始終在您指定的價位即時執行。",
            16:"可作杠杆交易",
            17:"靈活的的杠杆將有效規避交易所較高保證金要求的問題,您將以更小額的資金操控多種商品市場。",
            18:"深度的流動性",
            19:"受益於我們先進的市場解決方案,可以為您提供最好執行和市場深度,即使是大批量的交易都可以用盡可能低的滑點執行。"
            },
            download:{
            1:"實时的交易品種報價",
            2:"MT4是世界上最流行的交易平臺之一,具有自動監測,先進的圖表和實时分析的功能。",
            3:"名額技術分析工具",
            4:"超過50個內寘科技指示器來幫助你遵循市場和發現交易模式以及進場點和出場點訊號。",
            5:"功能全面的交易系統",
            6:"作為最流行的普遍交易平臺之一,MT4的優勢在於它的經典設計和簡單實用",
            7:"智慧交易系統",
            8:"利用從MQL4社群裏的各種各樣的EA方案,最大化地使用MT4平臺的功能。",
            9:'高效交易下單',
            10:'支持一擊成交、移動止損及部分平倉功能,可在開倉前預先設定止盈/止損。',
            11:"功能全面的交易系統",
            12:"作為最流行的普遍交易平臺之一,MT4的優勢在於它的經典設計和簡單實用。",
            13:'下載METATRADER 4並開通一個類比帳戶,免費接收所有這些！',
            },
            faq:{
            1:"帳戶",
            2:"基金",
            3:"平臺",
            4:"交易"
            },
            forex:{
                0:'為何在AEFOREX交易外匯',
                1:"我們致力於讓每個人都有機會參與外匯交易",
                2:"外匯合約規格",
                3:"產品",
                4:"說明",
                5:"多倉隔夜利息",
                6:"空倉隔夜利息",
                7:"最小規模",
                8:"契约價值",
                9:"波動幅度",
                10:"銀行間市場流動性",
                11:"使用知名的流動性供應商,可以為您提供最好的價格、執行和市場深度。",
                12:"一個帳戶交易全球",
                13:"使用知名的流動性供應商,可以為您提供最好的價格、執行和市場深度。",
                14:"毫秒級別的交易體驗",
                15:"使用知名的流動性供應商,可以為您提供最好的價格、執行和市場深度。",
                16:"享受極低的交易成本",
                17:"使用知名的流動性供應商,可以為您提供最好的價格、執行和市場深度。",
                18:"無重新報價",
                19:'使用知名的流動性供應商,可以為您提供最好的價格、執行和市場深度。',
                20:"靈活杠杆",
                21:"使用知名的流動性供應商,可以為您提供最好的價格、執行和市場深度。",
                22:"外匯基本知識"
                },
                fund:{
                1:"即時存款。快速提款。無需任何費用。",
                2:"通過我們廣泛的支付選項,輕鬆安全地為您的帳戶提供資金。您的資金安全地存放在一級澳大利亞銀行。",
                3:"存款",
                4:"提款",
                5:"方法",
                6:"處理時間",
                7:"最小值",
                8:"費用",
                9:"重要注意事項"
                },
                indicators:{
                1:"瞭解每個名額的重要性意味著你更有能力進行明智的交易",
                2:"指示器",
                3:"國家",
                4:"發佈日期",
                5:"一般影響"
                },
                policy:{
                1:"市場訂單",
                2:"限價訂單",
                3:"停止訂單",
                4:"執行政策"
                },
                program:{
                    1:"為什麼選擇與我們建立夥伴關係",
                    2:"介紹經紀人",
                    3:"我們的介紹經紀人（IB）計畫允許全世界的機构和個人通過向我們介紹新客戶而獲得報酬。從交易平臺到執行,再到交易結算,我們提供全面的解決方案。我們幫助他們開發業務,確保我們提供最出色的服務。",
                    4:"極具競爭力的合作計畫與傭金回報",
                    5:"詳細的推薦客戶跟進功能",
                    6:"隨時提現傭金,沒有隱藏費用",
                    7:"立即、自動計算傭金,查看傭金報表的全部內容",
                    8:"白標合作",
                    9:"我們的高級白標籤計畫提供完善的工具和資源,並且提供問題的最終解決方案,幫助你起步和運作。無論您是哪個階段的資金管理人與介紹經紀人,我們都能協助您成功搭建屬於自己的品牌",
                    10:"低啟動成本",
                    11:"完整的交易管理功能",
                    12:"原始/覈心點差與高傭金回報計畫",
                    13:"定制化的公司交易條件",
                    14:"機构計畫",
                    15:"利用與主要流動性提供商的長期合作關係,讓機構投資者在全球資本市場獲得競爭優勢。我們提供先進的解決方案,將最優流動性與多種執行策略結合。它靈活的集成選項專為當今複雜的交易環境設計。",
                    16:"全面的機构流動性解決方案",
                    17:"根據自身需要進行個性化設定",
                    18:"無最低交易量要求",
                    19:"多銀行報價,零成本橋接安裝",
                    20:"與我們一起管理您的帳戶,通過線上系統分析和跟踪您的成功。",
                    21:"全面的CRM管理系統",
                    22:"通過CRM管理系統推薦及管理您的用戶",
                    23:"傭金無限制",
                    24:"您的收入潜力沒有限制,您賺取的傭金完全取決於您所有客戶的交易量",
                    25:"經紀支持",
                    26:"專業團隊將協助您推薦的客戶開設帳戶並提供持續的管理和支持。",
                    27:"即期付款",
                    28:"高額傭金以及快捷付款和靈活解決方案,適合您的商業模式",
                    29:"定期激勵措施",
                    30:"我們會向推薦合作夥伴和新的潛在客戶提供定期激勵措施新增業績",
                    31:"自動返傭系統",
                    32:"條款和條件"
                    },
                    header:{
                    1:"",
                    2:"Meta Trader 4",
                    3:"全球最受歡迎的多資產類別交易平臺",
                    4:"首頁",
                    5:"首頁",
                    6:"交易產品",
                    7:"交易平臺",
                    8:"",
                    9:"交易帳戶",
                    10:"交易條件",
                    11:"",
                    12:"交易工具",
                    13:"",
                    14:"",
                    },
                    footer:{
                    1:"獲獎BR",
                    2:"利潤損失",
                    3:"世界金融",
                    4:"外匯周",
                    5:"歐洲貨幣",
                    6:"註冊地址：Level 7,333-339 George Street,SYDNEY NSW 2000 Australian",
                    7:"辦公地址",
                    8:"風險警示",
                    9: "本網站僅提供的只是一般資訊,並未考慮到您的個人目標、財務狀況及需求。囙此在决定獲得或繼續持有任何本網站列出的金融產品之前,您應當仔細考慮您的投資目標、經驗多寡和風險承受能力。您有可能損失部分或全部投資,如果無法承擔損失,請謹慎投資。您應當認識到與外匯交易有關的一切 風險,如果有疑問,請向獨立的金融顧問詢問意見",
                    10:"免責聲明",
                    11: "本網站上的所有資訊不構成對AURORA EXPLORE PTY LTD.任何金融產品的直接投資邀請或建議。網站內容僅供參考。網站訪問者不應依賴本網站上的資訊,並將其視為採取行動或不採取行動的依據。我們不對任何個人基於本網站或其資訊所採取的任何作為或不作為承擔任何責任,從而導致任何後果。本網站上提供 的任何產品和服務不適用於美國、加拿大、以色列和伊朗伊斯蘭共和國等特定國家的居民。 ",
                    12:"AURORA EXPLORE PTY LTD",
                    13:"電子郵件",
                    14:'電話',
                    15:'註冊號'
                    },
                    hores:{
                    1:"金融產品常規交易時間",
                    2:"外匯交易的一大優點就是能够每週5天,每天24小時進行。這是因為外匯市場在格林威治標準時間周日於雪梨開盤,於格林威治標準時間週五（夏令時）在紐約收盤。",
                    3:"夏令時（DST）主要適用於春秋兩季,但並不適用於所有金融產品。有些金融產品使用美國夏令時、歐洲夏令時或者亞太地區夏令時,有些則完全不用夏令時。交易時間使用的格林威治標準時間。",
                    4:"外匯",
                    5:"商品及貴金屬",
                    6:"CFDs",
                    7:"Shares",
                    8:"交易產品",
                    9:"開市時間",
                    10:"休市時間",
                    11:"交易間隔",
                    },
                    glossary:{
                        1:"外匯詞彙表闡明外匯市場上應用的術語和概念",
                        2:"名稱",
                        3:"描述"
                        },
                        indices:{
                        1:"為何在AEFOREX交易CFDs",
                        2:"提供多種常用的全球市場CFDs以及交易所交易指數",
                        3:"CFDs合約規格",
                        4:"產品符號",
                        5:"產品名稱",
                        6:"多倉隔夜利息",
                        7:"空倉隔夜利息",
                        8:"最小規模",
                        9:"新增規模",
                        10:"合約價值",
                        11:"外匯基本知識",
                        12:"你可能想知道的一些事情",
                        13:'即時參與全球經濟',
                        14:'為每一位交易者提供進入這些市場的平等機會,您可以通過交易指數差價合約,參與到舉世矚目的金融活動。',
                        15:'高杠杆低成本',
                        16:'提供更好的指數期貨合約杠杆,以較小的初始保證金獲得更大的市場曝光,釋放更多的資金交易新的訂單。',
                        17:'交易各地最流行的CFDs',
                        18:'覆蓋了來自世界各地最流行的指數,作為極有吸引力的多樣化投資組合的替代商品。',
                        19:'做空做多均能盈利',
                        20:'您可以通過買入或賣出,輕鬆地參與指數交易,做空和做多保證金要求相同,沒有額外的賣出規則。',
                        },
                        router:{
                        1:"首頁",
                        2:"外匯",
                        3:"商品和貴金屬",
                        4:"詳情",
                        5:"CFDs",
                        6:"MetaTrader 4",
                        7:"存款和取款",
                        8:"經濟指標",
                        9:"常見問題",
                        10:"市場分析",
                        11:"市場時間",
                        12:"執行政策",
                        13:"概念術語",
                        14:"公司簡介",
                        15:"使用條款",
                        16:"合作夥伴",
                        18:"監管安全",
                        17:"財經行事曆",
                        },
                        analysis:{
                        1:"市場分析",
                        2:"AE投研",
                        3:"品牌動態"
                        }
}