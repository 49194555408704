<template>
  <div id="app">
    <keep-alive include='ENdetails'>
      <router-view></router-view>
    </keep-alive>
    
  </div>
</template>

<script>
export default {
  name: "App",
  created(){
    const langtype=sessionStorage.getItem('locale')
    console.log(langtype)
    if(!langtype){
      sessionStorage.setItem('locale','en')
    }
  }
};
</script>

<style lang="less">
@import "../src/styles/global.less";
.discove {
  padding: 0px 20px;
}
</style>
