import vue from "vue";
let that = new vue();

export default {
  methods: {
    todownload() {
      if (this.isMobile()) {
        window.location.href =
          "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/metatrader4.apk";
      } else {
        var agent = navigator.userAgent.toLowerCase();
        var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
          //your code
          window.location.href =
            "https://download.metatrader.com/cdn/web/20115/mt4/auroraexplorepty4setup.exe";
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
          //your code
          window.location.href =
            "https://download.metatrader.com/cdn/web/20115/mt4/auroraexplorepty4setup.exe";
        }
        if (isMac) {
          //your code
          window.location.href =
            "https://download.mql5.com/cdn/mobile/mt4/ios?hl=zh";
        }
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    tologin() {
      window.location.href = "https://clients.aeforex-asia.com/";
    },
    get_languageType() {
      switch (sessionStorage.locale) {
        case "en":
          return 0;
        case "hk":
          return 2;
        case "cn":
          return 1;
      }
    },
  },
};
