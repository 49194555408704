function get_languageType() {
  let  locale = ''
  if (sessionStorage.locale) {
    locale  = sessionStorage.locale
  } else {
    locale  = 'en'
  }
  switch (locale) {
    case "en":
      return 0;
    case "hk":
      return 2;
    case "cn":
      return 1;
  }
}
const menuId = {
  Forex:{
    0:34,
    1:2,
    2:62
  },
  Commodity:{
    0:36,
    1:7,
    2:63
  },
  Indices:{
    0:37,
    1:10,
    2:64
  },
  Download:{
    0:38,
    1:11,
    2:65
  },
  Funding:{
    0:43,
    1:16,
    2:70
  },
  FAQ:{
    0:44,
    1:17,
    2:71
  },
  'Trading Hours':{
    0:45,
    1:18,
    2:72
  },
  'Order Execution Policy':{
    0:46,
    1:19,
    2:73
  },
  'Market Analysis':{
    0:48,
    1:21,
    2:75
  },
  'Economic Indicators':{
    0:49,
    1:22,
    2:76
  },
  'Terms Glossary':{
    0:50,
    1:23,
    2:77
  },
  'Introduce':{
    0:51,
    1:24,
    2:78
  },
  'Terms of Use':{
    0:52,
    1:25,
    2:79
  },
  'Supervise':{
    0:54,
    1:27,
    2:81
  }
  
}

const enRoutes = [
  
  {path:'/',redirect:'/EN/home'},
  {
    path: "/EN/home",
    name: "ENhome",
    title: "home",
    component: () => import("../../views/EN/home/home.vue"),
  },
  {
    path: "/EN/forex",
    name: "ENforex",
    title: "Forex",
    type: "pro",
    menuId:menuId['Forex'][get_languageType()],
    component: () => import("../../views/EN/forex/forex.vue"),
  },
  {
    path: "/EN/commodity",
    name: "ENmerchandise",
    title: "Commodity",
    menuId:menuId['Commodity'][get_languageType()],
    type: "pro",
    component: () => import("../../views/EN/commodity/commodity.vue"),
  },
  {
    path: "/EN/details",
    name: "ENdetails",
    title: "details",
    type: "",
    component: () => import("../../views/EN/details/details.vue"),
  },
  {
    path: "/EN/indices",
    name: "ENCFDS",
    title: "Indices",
    menuId:menuId['Indices'][get_languageType()],
    type: "pro",
    component: () => import("../../views/EN/indices/indices.vue"),
  },
  {
    path: "/EN/download",
    name: "ENdownload",
    title: "Download",
    menuId:menuId['Download'][get_languageType()],
    showType:1,
    type: "pro",
    component: () => import("../../views/EN/download/download.vue"),
  },
  {
    path: "/EN/funding",
    name: "ENdepositAndWithdraw",
    title: "Funding",
    menuId:menuId['Funding'][get_languageType()],
    type: "service",
    showType:1,
    component: () => import("../../views/EN/funding/funding.vue"),
  },
  {
    path: "/EN/indicators",
    name: "ENeconomicIndicators",
    title: "Economic Indicators",
    menuId:menuId['Economic Indicators'][get_languageType()],
    type: "market",
    component: () => import("../../views/EN/indicators/indicators.vue"),
  },

  {
    path: "/EN/faq",
    name: "ENaccountService",
    title: "FAQ",
    type: "service",
    menuId:menuId['FAQ'][get_languageType()],
    showType:'1',
    component: () => import("../../views/EN/faq/faq.vue"),
  },
  {
    path: "/EN/analysis",
    name: "ENmarketAnalysis",
    title: "Market Analysis",
    menuId:menuId['Market Analysis'][get_languageType()],
    type: "market",
    component: () => import("../../views/EN/analysis/analysis.vue"),
  },
  {
    path: "/EN/hours",
    name: "ENtradeTime",
    title: "Trading Hours",
    menuId:menuId['Trading Hours'][get_languageType()],
    type: "service",
    component: () => import("../../views/EN/hours/hours.vue"),
  },
  {
    path: "/EN/policy",
    name: "ENaccountServicePolicy",
    title: "Order Execution Policy",
    menuId:menuId['Order Execution Policy'][get_languageType()],
    type: "service",
    component: () => import("../../views/EN/policy/policy.vue"),
  },
 
  {
    path: "/EN/glossary",
    name: "ENConceptualTerms",
    title: "Terms Glossary",
    menuId:menuId['Terms Glossary'][get_languageType()],
    type: "market",
    component: () => import("../../views/EN/glossary/glossary.vue"),
  },
  {
    path: "/EN/about",
    name: "ENaboutUs",
    title: "Introduce",
    menuId:menuId['Introduce'][get_languageType()],
    type: "terms",
    component: () => import("../../views/EN/about/about.vue"),
  },
  {
    path: "/EN/terms",
    name: "ENclause",
    title: 'Terms of Use',
    menuId:menuId['Terms of Use'][get_languageType()],
    type: "terms",
    component: () => import("../../views/EN/terms/terms.vue"),
  },
  {
    path: "/EN/program",
    name: "ENpartner",
    title: "Liquidity Program",
    menuId:menuId['Introduce'][get_languageType()],
    type: "par",
    component: () => import("../../views/EN/program/program.vue"),
  },
    {
    path: "/EN/calendar",
    name: "ENfincialCalendar",
    title: "Calendar",
    type: "market",
    menuId:20,
    component: () => import("../../views/EN/calendar/calendar.vue"),
  },
    {
    path: "/EN/supervise",
    name: "ENSupervise",
    title: "Supervise",
    type: "terms",
    menuId:menuId['Supervise'][get_languageType()],
    component: () => import("../../views/EN/supervise/supervise.vue"),
  },
  
];
export default enRoutes;
