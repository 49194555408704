import Vue from 'vue'
import VueRouter from 'vue-router'
import enRoutes from './EN/index'
Vue.use(VueRouter)

const routes=[
      ...enRoutes
]

const router = new VueRouter({
    routes
  })
  
  export default router
  