import axios from "axios";
//let VUE_APP_API = "http://aeforexx.newmskjs.com";
 let VUE_APP_API = "https://en-api1.aeforex-live.com";
export async function ajax(param, url, post) {
  try{
    const { data } = await axios({
      method: post ? post : "post",
      url: `${VUE_APP_API}` + url,
      headers: { "Content-Type": "application/json; charset=utf-8" },
      data: param.data // 添加这一行来发送数据
    });
    return data;
  }catch(error){
    console.log(error);
    
  }
}
